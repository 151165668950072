import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { BlogEntry } from "../../../components";
import { DesktopRightImage, PhoneImage } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`A big part of the Alps is on Italian territory. From the highest mountain,
the Monte Bianco, to the Ligurian Alps at the Mediterranean sea and all the
way down to Sicily, Italy is very diverse. The excellent coffee and food is
the only constant.`}</p>
    <br />
    <Link className="text-black" to="/destination/italy/aosta" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/aosta_7.jpg" mdxType="DesktopRightImage" />
        <h2>{`Aosta`}</h2>
        <h4>{`Big mountains`}</h4>
        <p>{`To speak of one destination is a bit undersized. The over 80 km long valley
offers endless possibilities and ends at the highest mountain of the alps,
the Mont Blanc.`}</p>
        <PhoneImage src="destinations/aosta_7.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/italy/brixen" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/brixen_1.jpg" mdxType="DesktopRightImage" />
        <h2>{`Brixen`}</h2>
        <h4>{`Flow and alpine trails`}</h4>
        <p>{`High above the Eisack valley, the gondola spits you out with a breathtaking
view into the Dolomites. Different flowtrails are perfect for beginners. Or
you go fully alpine.`}</p>
        <PhoneImage src="destinations/brixen_1.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/italy/canazei" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/canazei_1.jpg" mdxType="DesktopRightImage" />
        <h2>{`Canazei`}</h2>
        <h4>{`Endless trails in the Dolomites`}</h4>
        <p>{`The Dolomites in Italy are famous for their unique mountains. High vertical
rock walls merge into mellow hills covered in lush meadows. From Canazei
several lifts bring you up to the bike park and natural singletracks.`}</p>
        <PhoneImage src="destinations/canazei_1.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/italy/paganella" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/paganella_3.jpg" mdxType="DesktopRightImage" />
        <h2>{`Dolomiti Paganella`}</h2>
        <h4>{`Bear Trails`}</h4>
        <p>{`Less known spot next to the Gardalake without it's loose rocks and
countless descents.`}</p>
        <PhoneImage src="destinations/paganella_3.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/italy/garda" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/garda_6.jpg" mdxType="DesktopRightImage" />
        <h2>{`Garda lake`}</h2>
        <h4>{`Rocks and icecream`}</h4>
        <p>{`Very popular spot that still offers new things and is already worth a trip
because of the lake and icecream.`}</p>
        <PhoneImage src="destinations/garda_6.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/italy/kronplatz" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/kronplatz_3.jpg" mdxType="DesktopRightImage" />
        <h2>{`Kronplatz`}</h2>
        <h4>{`Long Trails with a view into the Dolomites`}</h4>
        <p>{`Over 1200 metres of descent await you in almost every direction from the
cone like Kronplatz peak. With the lift, you can access trails in every
character, from flow to steep root trails.`}</p>
        <PhoneImage src="destinations/kronplatz_3.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/italy/lathuile" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/lathuile_3.jpg" mdxType="DesktopRightImage" />
        <h2>{`La Thuile`}</h2>
        <h4>{`Heaven for lift accessed natural enduro trails`}</h4>
        <p>{`Lift accessed mountain biking on long natural trails. Magnificent mountain
views. Italian food, coffee, and wine combined with a relaxed and
pleasant atmosphere.`}</p>
        <PhoneImage src="destinations/lathuile_3.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/italy/maremma" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/maremma_5.jpg" mdxType="DesktopRightImage" />
        <h2>{`Maremma`}</h2>
        <h4>{`Southern Tuscany`}</h4>
        <p>{`Maremma includes a few top spots for trail biking like Massa Marittima,
Punta Ala and Piombino. From mellow hills at the sea to enduro race tracks.
This variety combined with the Tuscan charm makes it a perfect place for
mountain biking for everyone.`}</p>
        <PhoneImage src="destinations/maremma_5.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/italy/reschen" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/reschen_1.jpg" mdxType="DesktopRightImage" />
        <h2>{`Reschenpass`}</h2>
        <h4>{`Enduro dreamland`}</h4>
        <p>{`The resort at the border of Austria and Italy is truly a gem for all
enduro fans. The lifts open up a wide area around the 1500m high
Reschenpass with it’s two beautiful lakes.`}</p>
        <PhoneImage src="destinations/reschen_1.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      